<template>
  <div>
    <v-card outlined>
      <v-card-title
        class="pa-3 pb-md-0 d-flex justify-space-between align-center"
      >
        Informações de cobrança
        <v-btn text icon color="primary" @click="openBillingInformationForm()">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <br v-if="form == null" />
      <v-card-text v-if="form != null" class="pa-3 pt-0">
        <div class="d-flex flex-column flex-md-row justify-space-between">
          <div class="d-flex flex-column">
            <span><b>Nome: </b> {{ form.name }} </span>
            <span v-if="form.person_type == 'br_cpf'">
              <b>CPF: </b> {{ $format.cpf(form.cpf) }}
            </span>
            <span v-if="form.person_type != 'br_cpf'">
              <b>CNPJ: </b> {{ $format.cnpj(form.cnpj) }}
            </span>
          </div>
          <div class="d-flex flex-column">
            <span><b> Email: </b> {{ form.email }} </span>
            <span><b> Telefone:</b> {{ $format.phone(form.phone) }}</span>
          </div>
        </div>
        <div class="text-wrap"><b>Endereço: </b> {{ address }}<br /></div>
      </v-card-text>
    </v-card>
    <BillingInformationFormDialog
      ref="BillingInformationFormDialog"
      @store="select()"
    />
  </div>
</template>

<script>
import BillingInformationFormDialog from "@/components/subscription/forms/BillingInformationFormDialog";
export default {
  components: {
    BillingInformationFormDialog,
  },

  data() {
    return {
      form: {
        main_address: {},
      },
    };
  },
  created() {
    this.select();
  },

  computed: {
    address() {
      let items = [];
      if (this.form.main_address) {
        if (this.form.main_address.address)
          items.push(this.form.main_address.address);

        if (this.form.main_address.number)
          items.push(this.form.main_address.number);

        if (this.form.main_address.complement)
          items.push(this.form.main_address.complement);

        if (this.form.main_address.district)
          items.push(this.form.main_address.district);

        if (this.form.main_address.postal_code)
          items.push(this.form.main_address.postal_code);

        if (this.form.main_address.city)
          items.push(this.form.main_address.city);

        if (this.form.main_address.state)
          items.push(this.form.main_address.state);

        return items.join(", ");
      }
    },
  },

  methods: {
    openBillingInformationForm() {
      this.$refs.BillingInformationFormDialog.open();
    },

    async select(id) {
      await this.$http
        .index("subscription/billing-information")
        .then((response) => {
          this.form = response.billing_information;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>

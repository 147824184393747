<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <SubscriptionViewMobile />
    </template>
    <template v-if="!$vuetify.breakpoint.mobile">
      <SubscriptionViewDesktop />
    </template>
  </div>
</template>

<script>
import SubscriptionViewMobile from "@/components/subscription/views/SubscriptionViewMobile";
import SubscriptionViewDesktop from "@/components/subscription/views/SubscriptionViewDesktop";

export default {
  components: {
    SubscriptionViewMobile,
    SubscriptionViewDesktop,
  },
};
</script>

<style>
</style>
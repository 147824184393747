<template>
  <v-card outlined>
    <v-card-text class="pa-3">
      <v-row class="d-flex flex-row align-center justify-space-around">
        <v-col cols="3">
          <v-avatar size="80">
            <v-img src="https://meu.psicoplanner.com.br/img/icons/android-chrome-192x192.png" />
          </v-avatar>
        </v-col>
        <v-col>
          <div>
            <div class="d-flex flex-row justify-space-between">
              <span class="pr-4">Plano atual</span>

              <!-- <template v-if="isSubscriptionEnded"> -->

              <template v-if="hideNextInvoice">
                <v-chip v-if="subscriptionIsActive" color="success" small>
                  Ativo
                </v-chip>
                <v-chip v-if="!subscriptionIsActive" color="error" small>
                  Suspenso
                </v-chip>
              </template>

              <template v-if="!hideNextInvoice">
                <v-chip :color="invoiceStatus.color" small>
                  {{ invoiceStatus.text }}
                </v-chip>
              </template>
            </div>
            <div>
              <b>{{ subscription.plan.name }}</b> |
              <b>{{ $format.moneyBr(subscription.plan.value) }}</b>
            </div>
            <div v-if="hideNextInvoice">
              <span>Proxima Cobrança </span>
              <br />
              <b class="success--text">
                {{
                  $format.dateBr(subscription.ends_at, "dd 'de' MMMM 'de' yyyy")
                }}
              </b>
            </div>
            <div class="mt-2">
              <template v-if="showPixBtn">
                <app-btn @click="openAppInvoiceDialog()" block small color="primary">
                  <app-icon left>qr_code</app-icon>
                  <span>Pagar com Pix</span>
                </app-btn>
              </template>

              <template v-if="showBillingPortalBtn">
                <app-btn @click="showBillingPortal()" block small color="primary">
                  <app-icon left>edit</app-icon>
                  <span>Gerenciar Assinatura</span>
                </app-btn>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <AppInvoiceDialog @update="$emit('update')" ref="AppInvoiceDialog" />
  </v-card>
</template>

<script>
import SubscriptionStatusChip from "@/components/subscription/sections/SubscriptionStatusChip.vue";
import AppInvoiceDialog from "@/components/subscription/sections/AppInvoiceDialog.vue";

export default {
  components: {
    SubscriptionStatusChip,
    AppInvoiceDialog,
  },

  computed: {
    subscriptionIsActive() {
      return this.$store.getters["subscription/subscriptionIsActive"];
    },
    subscription() {
      return this.$store.state.subscription.subscription;
    },
    hasSubscription() {
      return this.$store.getters["subscription/hasSubscription"];
    },
    isSubscriptionEnded() {
      return this.$store.getters["subscription/isSubscriptionEnded"];
    },
    forcePayment() {
      return this.$store.getters["subscription/forcePayment"];
    },
    lastInvoice() {
      return this.subscription.last_invoice;
    },

    invoiceStatus() {
      if (this.lastInvoice.status == 'past_due') {
        return {

          color: 'error',
          text: 'Atrasado'
        }

      }
      return {

        color: 'warning',
        text: 'Aguardando Pagamento'
      }
    },

    hideNextInvoice() {
      return !(this.lastInvoice && this.lastInvoice.status != "paid");
    },

    showPixBtn() {
      if (this.hasSubscription && this.subscription.vendor == "efi") {
        return (
          this.lastInvoice.status == "incomplete" ||
          this.lastInvoice.status == "waiting_payment" ||
          this.lastInvoice.status == "past_due"
        );
      }

      return false;
    },

    showBillingPortalBtn() {
      if (this.hasSubscription && this.subscription.vendor == "stripe") {
        return true;
      }

      return false;
    },
  },
  methods: {
    showBillingPortal() {
      this.$loading.start();
      this.$http.index("/subscription/billing-portal").then((response) => {
        location.href = response.url;
      });
    },

    openAppInvoiceDialog() {
      this.$refs.AppInvoiceDialog.open();
    },
  },
};
</script>

<style></style>

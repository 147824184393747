<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col cols="5">
        <template v-if="isSubscriptionCanceled">
          <v-card outlined>
            <v-card-text class="text-center pa-0">
              <br />
              <span class="pt-10" style="font-size: 50px">😕</span>
              <br />
              <br />
              <h3 class="secondary--text">
                Que Pena! sua assinatura está cancelada.
              </h3>
              <h3 class="secondary--text">Renove já!</h3>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
              <app-btn color="primary" @click="handleSubscribe()">
                Assine já
              </app-btn>
            </v-card-actions>
          </v-card>
        </template>
        <v-card v-if="isOnTrial && !isFreeAccount" outlined>
          <v-card-text class="text-center">
            <br />
            <span class="pt-10" style="font-size: 50px">🥳</span>
            <br />
            <br />
            <h3 class="secondary--text" v-if="!isTrialEnded">
              Você esta no período de testes. Termina em
              {{ daysToEndTrial }} dias
            </h3>
            <h3 class="secondary--text" v-if="isTrialEnded">
              Seu período de teste terminou!
            </h3>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <app-btn color="primary" @click="handleSubscribe()">
              Assine já
            </app-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="isFreeAccount" outlined>
          <v-card-text class="text-center">
            <br />
            <span class="pt-10" style="font-size: 50px">🤓</span>
            <br />
            <br />
            <h3 class="secondary--text">
              Você possui uma conta grátis! Use o Psicoplanner a vontade.
            </h3>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <app-btn color="primary" @click="handleSubscribe()">
              Assine já
            </app-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="hasSubscription && !isSubscriptionCanceled">
      <v-row>
        <v-col lg="4" xl="3" cols="3">
          <ActivePlanCard @update="init()" />
        </v-col>
        <v-col>
          <BillingInformation />
        </v-col>
      </v-row>
      <template v-if="showInvoices">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title> Assinaturas</v-card-title>
              <v-card-text>
                <v-data-table dense disable-pagination disable-sort hide-default-footer :headers="subscriptionHeaders"
                  :items="activeSubscription" :items-per-page="-1">
                  <template v-slot:[`item.plan`]="{ item }">
                    {{ item.plan.name }}
                  </template>
                  <template v-slot:[`item.next_renewal_date`]="{ item }">
                    <span v-if="item.next_renewal_date">
                      {{ $format.dateBr(item.next_renewal_date) }}
                    </span>
                    <span v-if="!item.next_renewal_date">
                      {{ $format.dateBr(item.ends_at) }}
                    </span>
                  </template>
                  <template v-slot:[`item.current_cycle`]="{ item }">
                    <span v-if="item.current_cycle">
                      {{ $format.dateBr(item.current_cycle) }}
                    </span>
                    <span v-if="!item.current_cycle"> ----- </span>
                  </template>
                  <template v-slot:[`item.start_at`]="{ item }">
                    {{ $format.dateBr(item.start_at) }}
                  </template>

                  <template v-slot:[`item.ends_at`]="{ item }">
                    {{ $format.dateBr(item.ends_at) }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip v-if="item.status == 'past_due'" color="error" small>
                      Atrasado
                    </v-chip>
                    <template v-if="item.status != 'past_due'">
                      <v-chip v-if="item.status != 'canceled'" color="success" small>
                        Ativo
                      </v-chip>
                      <v-chip v-if="item.status == 'canceled'" color="error" small>
                        Suspenso
                      </v-chip>
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-if="showInvoices">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title>Histórico de pagamentos </v-card-title>
              <v-card-text>
                <v-data-table dense disable-pagination disable-sort hide-default-footer :headers="invoicesHeaders"
                  :items="subscriptionInvoices" :items-per-page="-1">
                  <template v-slot:[`item.amount`]="{ item }">
                    {{ $format.decimal(item.amount) }}
                  </template>
                  <template v-slot:[`item.paid_at`]="{ item }">
                    {{ $format.dateBr(item.paid_at) }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <SubscriptionStatusChip small :status="item.status" />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
import SubscriptionStatusChip from "@/components/subscription/sections/SubscriptionStatusChip.vue";
import ActivePlanCard from "@/components/subscription/sections/ActivePlanCard.vue";
import BillingInformation from "@/components/subscription/sections/BillingInformation";

export default {
  components: {
    SubscriptionStatusChip,
    ActivePlanCard,
    BillingInformation,
  },

  data() {
    return {
      subscriptions: { data: [] },
      subscriptionHeaders: [
        { text: "Plano", value: "plan" },
        {
          text: "Próxima cobrança",
          value: "next_renewal_date",
          align: "center",
        },
        { text: "Ciclo atual", value: "current_cycle", align: "center" },
        { text: "Iniciou em", value: "start_at", align: "center" },
        { text: "Termina em", value: "ends_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "100px" },
      ],

      invoices: { data: [] },
      invoicesHeaders: [
        { text: "N°", value: "cycle", align: "center" },
        { text: "Valor", value: "amount", align: "center" },
        { text: "Data Pagamento", value: "paid_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "100px" },
      ],
    };
  },

  computed: {
    subscription() {
      return this.$store.state.subscription.subscription;
    },

    isFreeAccount() {
      return this.$store.getters["subscription/isFreeAccount"];
    },

    isOnTrial() {
      return this.$store.getters["subscription/isOnTrial"];
    },

    daysToEndTrial() {
      return this.$store.getters["subscription/daysToEndTrial"];
    },

    isTrialEnded() {
      return this.$store.getters["subscription/isTrialEnded"];
    },

    hasSubscription() {
      return this.$store.getters["subscription/hasSubscription"];
    },

    isSubscriptionCanceled() {
      return this.hasSubscription && this.subscription?.status == "canceled";
    },

    showInvoices() {
      return this.invoices.total > 0 && this.subscription.vendor != "pix";
    },

    subscriptionInvoices() {
      return this.invoices.data.filter((invoice) => invoice.subscription_id == this.subscription.id);
    },

    activeSubscription() {
      return this.subscriptions.data.filter((subscription) => subscription.id == this.subscription.id);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.select();
      this.selectInvoices();
    },

    select() {
      this.$loading.start();
      this.$http
        .index("subscription/subscription")
        .then((response) => {
          this.subscriptions = response.subscriptions;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    selectInvoices() {
      this.$loading.start();
      this.$http
        .index("subscription/efi/invoice")
        .then((response) => {
          this.invoices = response.invoices
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleSubscribe() {
      this.$router.push("/assinatura/checkout");
    },
  },
};
</script>

<style></style>

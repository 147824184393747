<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1000px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title primary-title> Informações de cobrança </v-card-title>
      <v-card-text>
        <BaseBillingInformationForm v-model="form" />
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="store()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseBillingInformationForm from "@/components/subscription/forms/BaseBillingInformationForm.vue";

export default {
  components: {
    BaseBillingInformationForm,
  },
  data() {
    return {
      dialog: false,
      form: {
        main_address: {},
      },
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    store() {
      this.$loading.start();

      this.$http
        .store("subscription/billing-information", this.form)
        .then((response) => {
          this.$loading.finish();
          this.dialog = false;
          this.$emit("store");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
